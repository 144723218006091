<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">{{ t("headings.branding") }}</h1>
    <Breadcrumb class="mt-3 mb-4" />
    <div class="card p-4">
      <custom-form
        v-if="formElements"
        :formElements="formElements"
        formStyle="inline"
        @submitForm="(data) => update(data)"
      />
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import CustomForm from "../components/form/CustomForm.vue";

export default {
  components: { Breadcrumb, CustomForm },
  setup() {
    const store = useStore();
    const { t } = useI18n();

    const company = computed(() => store.getters["company/getCompany"]);
    const user = computed(() => store.state.authentication.user);

    const waiting = computed(() => {
      if (store.state.authentication.waiting) return true;
      if (store.state.company.waiting) return true;
      return false;
    });

    const formElements = ref(null);

    const textOptions = ref([{ value: "light" }, { value: "dark" }]);

    const setFormElements = () => {
      formElements.value = [
        {
          class:
            "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
          element: "colorpicker",
          id: "primary-colorpicker",
          label: "primary_color",
          rules: ["isRequired"],
          value: company.value.primary[500],
          variable: "primary",
        },
        {
          class:
            "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
          element: "select",
          id: "primary-text-select",
          label: "primary_text_color",
          optionLabel: ["value"],
          options: textOptions.value,
          rules: ["isRequired"],
          translatableLabel: "labels",
          value: company.value.primaryText,
          variable: "primaryText",
        },
        {
          accept: "image/jpg, image/png, image/svg+xml",
          class:
            "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
          disabled: waiting,
          element: "filepicker",
          id: "logo-filepicker",
          label: "logo",
          multiple: false,
          rules: [],
          showFilename: true,
          textVariant: company.value.primaryText,
          type: "submit",
          value: null,
          variant: company.value.primary,
          variable: "logo",
        },
        {
          class: "col-span-12 text-right",
          element: "button",
          id: "submit-button",
          label: "save",
          textVariant: company.value.primaryText,
          type: "submit",
          variant: company.value.primary,
        },
      ];
    };

    const step = ref(32);
    const primaryStart = ref(500);
    const primaryShades = ref({
      50: "",
      100: "",
      200: "",
      300: "",
      400: "",
      500: "",
      600: "",
      700: "",
      800: "",
      900: "",
    });

    const generateShade = (color, amount) => {
      color = color.replace(/^#/, "");
      if (color.length === 3)
        color = color[0] + color[0] + color[1] + color[1] + color[2] + color[2];
      let [r, g, b] = color.match(/.{2}/g);
      [r, g, b] = [
        parseInt(r, 16) + amount,
        parseInt(g, 16) + amount,
        parseInt(b, 16) + amount,
      ];
      r = Math.max(Math.min(255, r), 0).toString(16);
      g = Math.max(Math.min(255, g), 0).toString(16);
      b = Math.max(Math.min(255, b), 0).toString(16);
      const rr = (r.length < 2 ? "0" : "") + r;
      const gg = (g.length < 2 ? "0" : "") + g;
      const bb = (b.length < 2 ? "0" : "") + b;
      return `#${rr}${gg}${bb}`;
    };

    const generateShades = (color, shades, start) => {
      shades[start] = color;
      let i = start;
      while (i < 900) {
        if (i === 50) {
          i = 100;
          color = generateShade(color, -step.value / 2);
        } else {
          i += 100;
          color = generateShade(color, -step.value);
        }
        shades[i] = color;
      }
      color = shades[start];
      i = start;
      while (i > 50) {
        if (i === 100) {
          i = 50;
          color = generateShade(color, step.value / 2);
        } else {
          i -= 100;
          color = generateShade(color, step.value);
        }
        shades[i] = color;
      }
    };

    const update = (formData) => {
      generateShades(formData.primary, primaryShades.value, primaryStart.value);

      const data = {
        companyId: company.value.id,
        logo: formData.logo,
        primary: primaryShades.value,
        primaryText: formData.primaryText,
      };

      store.dispatch("company/updateCompanyTheme", data);
    };

    onMounted(() => {
      if (!company.value.id) {
        store.dispatch("company/getCompany", user.value.companyId);
      } else {
        setFormElements();
      }
    });

    watch(
      () => waiting.value,
      (waiting) => {
        if (!waiting) {
          setFormElements();
        }
      }
    );

    return {
      formElements,
      t,
      primaryShades,
      primaryShades,
      update,
    };
  },
};
</script>
